<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @submit="handleSubmit" :form="form">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="日期">
              <a-date-picker style="width: 100%" format="YYYY-MM-DD" :disabled-date="disabledDate" v-model="queryDate"
                showToday placeholder="Select Time" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="用户名">
              <a-input v-decorator="[
                'adminusername',
                {
                  initialValue: queryParam.adminusername
                }
              ]" placeholder="用户名" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button htmlType="submit" :loading="submitLoading" type="primary">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="data" :pagination="pagination"
      :loading="loading" @change="handleTableChange">
      <span slot="login_time" slot-scope="text">
        {{ text | dayjs }}
      </span>
      <span v-if="text !== 0" slot="logout_time" slot-scope="text">
        {{ text | dayjs }}
      </span>
    </a-table>
  </a-card>
</template>
<script>
import { DatePicker as ADatePicker } from 'ant-design-vue'
import { systemAdminLog } from '@/api/system'
import merge from 'webpack-merge'
import moment from 'moment'

const statusMap = {
  'Active': {
    status: 'success',
    text: '激活'
  },
  'Closed': {
    status: 'default',
    text: '关闭'
  },
  'Error': {
    status: 'error',
    text: '异常'
  }
}

const dateConfig = {
  rules: [{ type: 'object', required: true, message: 'Please select time!' }]
}

const columns = [
  {
    sorter: true,
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '用户名',
    dataIndex: 'adminusername'
  },
  {
    title: '登录时间',
    dataIndex: 'login_time',
    scopedSlots: { customRender: 'login_time' }
  },
  {
    title: '退出时间',
    dataIndex: 'logout_time',
    scopedSlots: { customRender: 'logout_time' }
  },
  {
    title: 'IP',
    dataIndex: 'ipaddress'
  }
]

export default {
  components: {
    ADatePicker
  },
  data() {
    return {
      description: '系统活动日志',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      dateConfig: dateConfig,
      // form
      form: this.$form.createForm(this),
      // 查询参数
      queryParam: {
        adminusername: this.$route.query.adminusername
      },
      dateFormat: 'YYYY-MM-DD',
      date: '',
      queryDate: null,
      data: [],
      pagination: {},
      loading: false,
      columns,
      submitLoading: false,
      tableLoading: false
    }
  },
  created() {
    this.date = this.$route.query.date
  },
  mounted() {
    const params = this.$route.query
    this.fetch(params)
  },
  watch: {
    date(val) {
      if (val) {
        this.queryDate = moment(val, this.dateFormat)
      }
    },
    queryDate(val) {
      console.log(val)
      if (val) {
        this.date = val.format('YYYY-MM-DD')
      } else {
        this.date = ''
      }
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  methods: {
    moment,
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf('day')
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      // 带上查询参数
      const queryParam = this.form.getFieldsValue()
      if (this.date) {
        queryParam.date = this.date
      }
      const params = {
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        ...queryParam
      }
      this.fetch(params)
      this.$router.push({
        query: merge({}, params)
      })
    },
    fetch(params) {
      console.log(params)
      this.loading = true
      new Promise((resolve, reject) => {
        systemAdminLog(params).then(response => {
          if (response.status === 'success') {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
      }).then(data => {
        console.log(data)
        const pagination = { ...this.pagination }
        this.loading = false
        this.data = data.list.data
        pagination.total = data.list.total
        pagination.pageSize = data.list.per_page
        pagination.current = data.list.current_page
        this.pagination = pagination
      })
    },
    resetQuery() {
      // 重置查询条件，并重新请求
      this.form.setFieldsValue({
        date: '',
        adminusername: ''
      })
      this.$router.push({
        query: merge({}, {})
      })
      this.submitLoading = true
      this.fetch()
      this.submitLoading = false
    },
    handleSubmit(e) {
      // 重置查询条件，并重新请求
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitLoading = true
          if (this.date) {
            values.date = this.date
          }
          this.fetch(values)
          this.submitLoading = false
          this.$router.push({
            query: merge({}, values)
          })
        }
      })
    },
    goEdit(record) {
      this.$router.push({ 'name': 'ClientsDetail', query: { id: record.id } })
    }
  }
}
</script>
